<!--
 * @Author: jiang
 * @Date: 2021-07-18 16:41:52
 * @Description: 个人任务统计
-->
<template>
  <el-container style=" height: 100%; padding: 0 15px 15px;">
    <el-header class="g-bg header">
      <div class="name">{{ $route.query.name }}任务反馈统计</div>
      <el-date-picker
        style="width: 300px; margin-left: auto;"
        v-model="searchData.time"
        type="daterange"
        value-format="yyyy-MM-dd"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        @change="fetchList"
      ></el-date-picker>
    </el-header>
    <el-main
      class="g-bg g-mt-15 g-p-20"
      v-loading="loading"
    >
      <el-tabs v-model="activeTab">
        <el-tab-pane
          :label="item.label"
          :name="item.value"
          v-for="item in tabList"
          :key="item.value"
        ></el-tab-pane>
      </el-tabs>
      <div
        v-if="list.length === 0"
        class="g-empty"
      ></div>
      <div
        class="list-box"
        :style="{height: boxHeight + 'px'}"
      >
        <div
          ref="item"
          class="item"
          v-for="(item, index) in list"
          :key="item.id"
          :style="{top: item._top + 'px', left: item._left}"
          :data-index="index"
          @click="onNav(item)"
        >
          <div class="task-item">
            <div class="title g-text-ellipsis2">{{ item.name }}</div>
            <div class="dept-box g-mb-5">
              <el-tag
                style="margin-right: 8px;"
                v-for="it in item.executive_departments"
                :key="it.id"
                type="primaty"
                effect="plain"
              >
                {{ it.name }}
              </el-tag>
            </div>
            <div class="dept-box g-mb-5">
              <el-tag
                style="margin-right: 8px;"
                v-for="it in item.executive_users_data"
                :key="it.id"
                type="primaty"
                effect="plain"
              >
                {{ it.name }}
              </el-tag>
            </div>
            <div class="info">
              <span>{{ item.user.department.name }}</span>
              <span class="g-mx-5">{{ item.user.name }}</span>
              <span class="g-mx-5">发布于</span>
              <span>{{ item.executive_end_data }}</span>
            </div>
          </div>
        </div>
      </div>
      <b-pagination
        :page="page"
        @change="fetchList"
      ></b-pagination>
    </el-main>
  </el-container>
</template>

<script>
import { getUserTaskPage } from '@/api/manager'
import { customTaskStatus } from '@/config/dict'

export default {
  data() {
    return {
      id: null,
      loading: false,
      tabList: customTaskStatus,
      activeTab: 'progress',
      searchData: {
        time: [],
      },
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
      boxHeight: 100,
    }
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        if (id) {
          this.id = id
          this.onSearch()
        }
      },
      immediate: true,
    },
    'activeTab'() {
      this.onSearch()
    },
  },
  methods: {
    fetchList() {
      this.loading = true
      const params = {
        type: this.activeTab,
        page: this.page.current,
        start_date: this.searchData.time[0],
        end_date: this.searchData.time[1],
      }
      getUserTaskPage(this.id, params)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
          this.$nextTick(() => {
            const items = this.$refs.item
            // console.log(items)
            if (items) {
              const arr = [0, 0]
              items.forEach(item => {
                const index = arr[0] > arr[1] ? 1 : 0
                const listIndex = +item.dataset.index
                this.list[listIndex]._top = arr[index]
                // console.log(this.list[listIndex], listIndex, index, item.offsetHeight)

                if (index === 1) {
                  this.list[listIndex]._left = '50%'
                }
                arr[index] = arr[index] + item.offsetHeight
              })
              this.boxHeight = arr[0] > arr[1] ? arr[0] : arr[1]
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSearch() {
      this.page.current = 1
      this.fetchList()
    },
    onNav(row) {
      this.$router.push({
        path: '/task/info',
        query: { id: row.id, type: 'task' },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;

  .name {
    margin-right: 15px;
    font-size: 20px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.list-box {
  position: relative;

  .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    padding: 8px;
  }
}

.task-item {
  padding: 20px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background: rgba($color: #dfe3e8, $alpha: 0.4);
  }

  .title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
    color: #2b2d42;
  }

  .info {
    font-size: 14px;
    color: #747e8f;
  }
}
</style>
